import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { CiInstagram, CiFacebook } from 'react-icons/ci'
import { Link } from 'gatsby'

export default function Footer() {
  return <footer
    className='bg-henkotsu w-full px-4 py-8 sm:px-10 md:px-16 xl:py-16 xl:px-28'
  >
    <div className='xl:flex max-w-screen-xl'>
      <nav className='lg:flex lg:gap-16 mx-auto'>
        <Link to='/'>
          <StaticImage
            className='w-32 mx-auto sm:w-40 xl:w-56'
            alt='Main logo image'
            src='../../images/logos/logo.webp'
          />
        </Link>
        <div
          className='text-sm flex flex-wrap mt-6 mx-auto text-gray-600 sm:w-[620px] lg:w-[780px] lg:text-base lg:mt-2 xl:mt-6'
        >
          {/* <Link to='/'><p className='mx-2 mt-3 lg:mx-4'>ホーム</p></Link> */}
          <p className='mx-2 mt-3 lg:mx-4'>
            <Link to='/about'>
              へんこつについて
            </Link>
          </p>
          <p className='mx-2 mt-3 lg:mx-4'>
            <Link to='/menu'>
              メニュー
            </Link>
          </p>
          <p className='mx-2 mt-3 lg:mx-4'>
            <Link to='/branches'>
              店舗一覧
            </Link>
          </p>
          {/* <p className='mx-2 mt-3 lg:mx-4'>
            <Link to='/'>
              テイクアウト
            </Link>
          </p> */}
          <p className='mx-2 mt-3 lg:mx-4'>
            <Link to='/careers'>
              採用情報
            </Link>
          </p>
          <p className='mx-2 mt-3 lg:mx-4'>
            <Link to='/contact'>
              お問い合せ
            </Link>
          </p>
          <p className='mx-2 mt-3 lg:mx-4'>
            <Link to='/companyInfo'>
              会社情報
            </Link>
          </p>
          <p className='mx-2 mt-3 lg:mx-4'>
            <a
              href='http://www.contents.henkotu.co.jp/info/wp-content/uploads/Privacy_policy.pdf'
              target='_blank'
              rel='noopener noreferrer'
            >
              プライバシーポリシー
            </a>
          </p>
        </div>
      </nav>
      <div className='flex justify-end gap-4 text-4xl text-primary-500 mt-8 xl:mt-20'>
        <a
          href='https://www.instagram.com/henkotsu_udon/'
          aria-label='Instagram of henkotu'
        >
          <CiInstagram />
        </a>
        <a
          href='https://www.facebook.com/HenkotsuUdon/?locale=ja_JP'
          aria-label='facebook of henkotu'
        >
          <CiFacebook />
        </a>
      </div>
    </div>
    <p className='text-center text-gray-600 leading-relaxed text-xs pt-8 lg:text-sm lg:mt-12'>
      © 2024 - うどんれすとらん へんこつ ALL RIGHTS RESERVED
    </p>
  </footer>
}